<template>
  <section class="section-package-detail">
    <div v-if="tourData">
      <b-container
        class="package-detail-content mt-3 mt-md-5 mb-4 pt-0 pt-md-4"
      >
        <b-row>
          <b-col cols="12" md="5">
            <b-carousel
              class="pd-detail-slide"
              id="carousel-1"
              v-model="slide"
              :interval="6000"
              indicators
              background="#ababab"
              img-width="1280"
              img-height="400"
              style="text-shadow: 1px 1px 2px #333"
            >
              <b-carousel-slide v-if="tourData.picture1">
                <template #img>
                  <img
                    v-if="tourData.typeImageOrVideo1 == '1'"
                    class="d-block img-fluid w-100 relative"
                    width="1024"
                    :src="oss+tourData.picture1"
                    alt="image slot"
                  />
                  <video
                    v-else
                    controls
                    width="100%"
                    height="395px"
                    class="relative"
                  >
                    <source :src="oss+tourData.picture1" />
                  </video>
                  <p
                    class="package-text-slide absolute"
                    style="top: 0; left: 0"
                  >
                    {{ tourData.attractionDescription1 }}
                  </p>
                </template>
              </b-carousel-slide>
              <b-carousel-slide v-if="tourData.picture2">
                <template #img>
                  <img
                    v-if="tourData.typeImageOrVideo2 == '1'"
                    class="d-block img-fluid w-100 relative"
                    width="1024"
                    :src="oss+tourData.picture2"
                    alt="image slot"
                  />
                  <video
                    v-else
                    controls
                    width="100%"
                    height="395px"
                    class="relative"
                  >
                    <source :src="oss+tourData.picture2" />
                  </video>
                  <p
                    class="package-text-slide absolute"
                    style="top: 0; left: 0"
                  >
                    {{ tourData.attractionDescription2 }}
                  </p>
                </template>
              </b-carousel-slide>
              <b-carousel-slide v-if="tourData.picture3">
                <template #img>
                  <img
                    v-if="tourData.typeImageOrVideo3 == '1'"
                    class="d-block img-fluid w-100 relative"
                    width="1024"
                    :src="oss+tourData.picture3"
                    alt="image slot"
                  />
                  <video
                    v-else
                    controls
                    width="100%"
                    height="395px"
                    class="relative"
                  >
                    <source :src="oss+tourData.picture3" />
                  </video>
                  <p
                    class="package-text-slide absolute"
                    style="top: 0; left: 0"
                  >
                    {{ tourData.attractionDescription3 }}
                  </p>
                </template>
              </b-carousel-slide>
            </b-carousel>
          </b-col>
          <b-col cols="12" md="7">
            <div class="pp-summary">
              <div class="pb-3 flex">
                <h3 class="pd-tour-title pt-3 pt-md-0">
                  {{ tourData.specialTitle }}
                </h3>
                <a
                  v-if="tourData.flyer"
                  :href="oss+tourData.flyer"
                  target="_blank"
                  class="btn btn-warning add-cart float-right"
                  variant="warning"
                  rel="Flyer file"
                  >{{ $t('packageDetail.download') }}</a
                >
              </div>
              <div class="pd-sub-content p-3">
                <div class="d-lg-flex">
                  <div class="pr-3">
                    {{ $t('packageDetail.Country') }}: &nbsp;
                    {{ tourData.country }}
                  </div>
                  <div>
                    <span>{{ $t('packageDetail.Departure_date') }}:</span>
                    <b-form-select
                      v-model="selected"
                      :options="options"
                      size="sm"
                      class="departures-date ml-3"
                      @change="changDate(selected)"
                    ></b-form-select>
                  </div>
                </div>
                <div class="pp-summary-bellow">
                  {{ tourData.longDescription }}
                </div>
              </div>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <App
            class="mb-3"
            :departuresCurrent="departures"
            :userTypeCurrent="userType"
            @selectDepartureDate="changDate"
          />
        </b-row>
        <div class="text-right py-3"> <!-- 立即预定 -->
          <a
            v-if="isAuthenticated == true"
            class="pd-booking btn btn-warning add-cart text-white"
            squared
            variant="warning"
            @click="checkSelectedDeparture"
            >{{ $t('packageDetail.Book_now') }}</a
          >
          <!-- 立即预定 未登录-->
          <a
            v-else
            href="javascript:void(0);"
            v-b-modal="'modal-b2'"
            v-on:click="saveLink('#/booking')"
            :link-redirect="currentUrl"
            class="pd-booking btn btn-warning add-cart"
            :class="{
              disabled:
                departureDateSelected === null && selected === null
                  ? true
                  : false,
            }"
            :disabled="departureDateSelected && selecte"
            squared
            variant="warning"
            >{{ $t('packageDetail.Book_now') }}</a
          >
        </div>
        <b-row class="pb-3" v-if="userType && userType !== 'USER'">
          <b-col class="inline d-flex sm-mt-0" sm="12" md="3">
            <div class="pd-cl-green inline mr-3"></div>
            {{ $t('packageDetail.Confirm') }}</b-col
          >
          <b-col class="inline d-flex sm-mt-0" sm="12" md="3">
            <div class="pd-cl-yellow inline mr-3"></div>
            {{ $t('packageDetail.Not_Confirm') }}</b-col
          >
          <b-col class="inline d-flex sm-mt-0" sm="12" md="3">
            <div class="pd-cl-red inline mr-3"></div>
            {{ $t('packageDetail.Full') }}</b-col
          >
        </b-row>
      </b-container>

      <b-container
        class="package-detail-description mt-3 pb-5"
        v-if="tourData.itinerary"
      >
        <div class="package-detail-tab">
          <div>
            <b-tabs content-class="mt-4">
              <b-tab :title="$t('layout.tourist_project')" active>
                <div class="tour-pg-pdf mt-2" v-if="checkImageCurrent === 1">
                  <img
                    v-if="tourData.itinerary"
                    :src="oss+tourData.itinerary"
                    alt="Image"
                  />
                </div>
                <div v-else>
                  <pdf
                    :src="oss+tourData.itinerary"
                    v-for="i in numPages"
                    :key="i"
                    :page="i"
                  ></pdf>
                </div>
              </b-tab>
            </b-tabs>
          </div>
        </div>
      </b-container>
    </div>
    <div v-else>
      <b-container v-if="!loading">
        <Spinner />
      </b-container>
      <div v-else class="container d-flex justify-content-center">
        <img src="../../assets/img/search_not_found.png" alt="Image" />
      </div>
    </div>
    <b-modal
      centered
      size="lg"
      ref="modal-message-error"
      id="modal-message-error"
      :hide-footer="true"
    >
      <div class="row text-center justify-content-center">
        <img
          class=""
          src="../../assets/img/icon/icon-hint-red@2x.png"
          style="width: 60px"
          alt="Image"
        /><br />
        <p v-if="!ischeckSelectDepartureOverTime" class="my-4 text-center w-100">
          {{ $t('booking.checkSelectDeparture') }}
        </p>
        <p v-else class="my-4 text-center w-100">
          {{ $t('booking.checkSelectDepartureOverTime') }}
        </p>
      </div>
    </b-modal>
  </section>
</template>
<script>
import pdf from 'vue-pdf';
import Spinner from '../spinner/Spinner';
import configUrl from '@/global/config.js'
export default {
  name: 'PackageDetail',
  components: {
    App: () => import('./app'),
    pdf,
    Spinner,
  },
  data() {
    return {
      numPages: undefined,
      tourData: null,
      loading: true,
      errored: false,
      infolength: null,
      slug: null,
      departures: null,
      departures_date: [],
      selected: null,
      options: null,
      checkImageCurrent: 1,
      lang: this.$language,
      departureDateSelected: null,
      zoom: 100,
      userType: null,
      selectedDate: null,
      ischeckSelectDepartureOverTime: false,
       oss:'',
       currentUrl:'',
    };
  },
  computed: {
    isAuthenticated() {
      return this.$store.getters['authModule/getAuthentication'];
    },
  },
  mounted: function () {
    this.oss=configUrl.oss_path;
    this.userType = this.$store.getters['authModule/getUserType'];
    this.fetchData();
  },
  watch: {
    '$route.params': function () {
      window.location.reload();
    },
  },
  methods: {
    fetchData() {
      this.loading = false;
      this.lang = this.$language;
      var param = this.$route.params.package_code;
      this.$store
        .dispatch('tourModule/fetchTourData', {"packageCode":param})
        .then(() => {
          this.loading = true;
          this.tourData = this.$store.getters['tourModule/getTourData'];
          this.departures = this.tourData.departureVoList;
          let departures_date = [];

          departures_date[0] = {
            value: null,
            text: this.lang.packageDetail.Select_Date,
            notEnabled: true,
            disabled: true,
          };
          if (this.departures.length > 0) {
            var today = new Date();
            today.setHours(0, 0, 0, 0);
            var timestampToday = today.getTime() + 86400000;
            for (var i = 1; i <= this.departures.length; i++) {
              if (this.departures[i - 1].departureDate !== null) {
                var d = new Date(
                  this.departures[i - 1].departureDate + 'T00:00:00',
                );
                var timestampTour = d.getTime();
                var getMonth = d.getMonth() + 1;
                if (getMonth.toString().length == 1) getMonth = '0' + getMonth;
                var getDate = d.getDate();
                if (getDate.toString().length == 1) getDate = '0' + getDate;
                if (timestampTour > timestampToday) {
                  var datestring = this.departures[i - 1].departureDate;
                  departures_date[i] = {
                    value: datestring,
                    text: datestring,
                    id: this.departures[i - 1].id,
                    booked: this.departures[i - 1].bookedCount,
                    reservedNumber:this.departures[i - 1].reservedNumber,
                    disabled:
                      this.departures[i - 1].bookedCount >=
                      this.departures[i - 1].maxPax
                        ? true
                        : false,
                  };
                  this.departures_date.push(datestring);
                }
              }
            }
            // this.options = departures_date;
          }
          this.options = departures_date;

          if (this.tourData.itinerary) {
            let loadingTask = pdf.createLoadingTask(this.tourData.itinerary);
            loadingTask.promise.then((pdf) => {
              this.numPages = pdf.numPages;
            });
            if (this.checkImage(this.tourData.itinerary) === 1) {
            } else {
              this.checkImageCurrent = 0;
            }
          }
        })
        .catch((error) => {
          console.log(error);
          this.errored = true;
        });
    },
    checkImage(file) {
      let type = file.split('.').pop();
      if (type === 'pdf') {
        return 0;
      }
      return 1;
    },
    changDate: function (value) {
      if (this.tourData.tourLocked) {
        return;
      }
      this.departureDateSelected = null;
      this.selected = null;
      for (const data of this.departures_date) {
        this.departureDateSelected = value.toString().replace(/,/g, '-');
        let myArr = this.departureDateSelected.toString().split('-');
        let day = ('0' + myArr[2]).slice(-2);
        let month = ('0' + myArr[1]).slice(-2);
        this.departureDateSelected = myArr[0] + '-' + month + '-' + day;
        this.selectedDate = myArr[0] + '-' + month + '-' + day;
        if (this.departureDateSelected === data) {
          this.selected = this.departureDateSelected;
          let keys = Object.keys(this.options);

          const keySelected = keys.filter((key) => {
            let item = this.options[key];
            return item.value === this.selected;
          });
          sessionStorage.setItem('departureId', this.options[keySelected].id);
          sessionStorage.setItem(
            'bookedCount',
            this.options[keySelected].booked,
          );
          break;
        } else {
          sessionStorage.removeItem('departureId');
          sessionStorage.removeItem('bookedCount');
          this.selected = null;
          this.departureDateSelected = null;
        }
      }

      if (this.departureDateSelected == null) {
        this.selected = null;
        return;
      }
      const myArr = this.departureDateSelected.split('-');
      let day = myArr[2].substring(0, 1);
      let month = myArr[1].substring(0, 1);
      if (day == 0) myArr[2] = myArr[2].substring(1, 2);
      if (month == 0) myArr[1] = myArr[1].substring(1, 2);
      // sessionStorage.setItem('booked', '')
      this.$root.$refs.calendar.setDateOfCalendar(this.departureDateSelected);
    },
    saveLink: function (value) {
      this.$root.$refs.A.setTabIndex(1);
      const currentUrl = window.location.origin+window.location.pathname + value;
      this.currentUrl=currentUrl;
      sessionStorage.setItem('before_url', currentUrl);
    },

    checkSelectedDeparture: function () {
      if (this.$store.getters['authModule/getAuthentication']) {
        const check = this.departureDateSelected && this.selected;
        if (!check) {
          let today = new Date();
          today.setHours(0, 0, 0, 0);
          let timestampToday = today.getTime() + 86400000;
          const hasDate = this.departures.filter(
            (val) => val.departureDate === this.selectedDate,
          );
          if (hasDate.length > 0) {
            var timestampTour = new Date(hasDate[0].departureDate + 'T00:00:00').getTime();
          }
          this.$refs['modal-message-error'].show();
          if (timestampToday === timestampTour) {
            this.ischeckSelectDepartureOverTime = true;
            return false;
          }
          this.ischeckSelectDepartureOverTime = false;
          return false;
        }
        this.$router.push('/booking');
      }
    },
  },
};
</script>
<style type="text/css">
.package-detail-content,
.package-detail-description {
  background-color: #fff;
}
embed {
  background-color: transparent;
}
.package-detail-description {
  padding: 0;
}
header.header-global {
  background: #fff;
}
.package-detail-tab .nav-tabs .nav-item .nav-link.active {
  border-bottom: #f6a604 solid 4px;
  border-top: unset;
  color: #f6a604;
}
.package-detail-tab .nav-tabs .nav-link {
  border: unset;
  border-color: unset;
}
.package-detail-tab .nav-tabs {
  border-bottom: 3px solid #d1d2db;
  -webkit-box-shadow: 0 4px 6px -6px #222;
  -moz-box-shadow: 0 4px 6px -6px #222;
  box-shadow: 0 4px 6px -6px #222;
  border-bottom: 2px solid #dbd6d6;
  padding: 0 4.5rem;
  /*border-bottom: unset;*/
}
.package-detail-tab .tabs {
  padding: 0.7rem 0 0.5rem;
}
.package-detail-tab .nav-tabs .nav-item .nav-link {
  font-size: 1.2rem;
}
.package-detail-tab .nav-tabs {
  display: none;
}
.package-detail-tab .nav-link {
  padding: 0 0 0.5rem;
}
.package-detail-tab .nav-tabs .nav-item {
  margin-bottom: -2px;
}
.tour-pg-pdf img {
  width: 100%;
}
.package-text-slide {
  position: absolute;
  left: -15px;
  z-index: 10;
  border-bottom-right-radius: 10px;
  color: #fff;
  top: -75px;
  background-color: #f6a604;
  font-size: 1.3rem;
  padding: 0 15px;
}
.package-detail-content .carousel-caption p {
  margin-bottom: 0.7rem;
}
.carousel-indicators li {
  width: 12px;
  height: 12px;
  border-radius: 99%;
}
.pd-tour-title span {
  color: #4b484891;
  font-size: 1.3rem;
}
h3.pd-tour-title {
  color: #454343;
  font-weight: 100;
  letter-spacing: 1px;
  font-size: 1.4rem;
}
.pd-title-sub {
  color: #f6a604;
}
.pd-title-sub span {
  background-color: #ffa50038;
  padding: 0.1rem 0.5rem;
}
.pd-title-sub-ic img {
  width: 20px;
  opacity: 0.5;
  margin-top: -3px;
}
.pd-title-sub span.pd-title-sub-ic {
  background-color: unset;
  color: #afa3a3;
}
.pd-sub-content {
  background-color: #e0eaeb47;
  min-height: 18.2rem;
}
p.pd-price {
  color: #ff0200a6;
  font-size: 1.3rem;
  margin-bottom: 0.4rem;
}
a.add-cart {
  font-size: 1.2rem;
  background-color: #f6a604;
  padding: 0.35rem 1.2rem;
  border-radius: 4px !important;
  border: 1px solid #f6a604;
  color: #fff;
}
a.add-cart:hover {
  border: 1px solid #f6a604;
}
.pp-summary-bellow {
  line-height: 2.4;
  color: #00000070;
  letter-spacing: 3px;
  font-size: 0.9rem;
  white-space: pre-line;
  height: 14rem;
  margin-bottom: 0.9rem;
  overflow: auto;
}
.pd-price span:nth-child(2) {
  font-size: 1.1rem;
  color: #f6a6048f;
  margin-left: 0.5rem;
}
.pd-price span:nth-child(3) {
  font-size: 0.9rem;
  color: #00000069;
  margin-left: 0.5rem;
}
.kl-calendar {
  padding: 1rem;
}
.package-detail-content .col-5.col {
  padding-right: 7px;
}
.package-detail-content .col-7.col {
  padding-left: 8px;
}
select.departures-date {
  height: 27px;
  width: 160px;
  background: url(/icon/icon-calendar.png) no-repeat right #fff;
  background-size: 18px;
  -webkit-appearance: none;
  background-position: 8rem;
  border-radius: unset;
  color: #666666;
}
.btn-outline-secondary.disabled,
.btn-outline-secondary:disabled {
  color: #fff;
  background-color: #f6a604;
}
.disabled {
  opacity: 0.5;
}
.pd-detail-slide img {
  height: 25rem;
}
.pd-cl-green {
  width: 30px;
  height: 30px;
  background-color: green;
}
.pd-cl-red {
  width: 30px;
  height: 30px;
  background-color: red;
}
.pd-cl-yellow {
  width: 30px;
  height: 30px;
  background-color: #f6a604;
}
/*** Firefox 81 PDF Viewer Dark Theme for userContent.css ***/
/* 
    These rules are from 
    resource://pdf.js/web/viewer.css
    Line 456, the lines within:
    @media (prefers-color-scheme: dark) { :root { } }
  */
[mozdisallowselectionprint] > body {
  --main-color: rgba(249, 249, 250, 1);
  --body-bg-color: rgba(42, 42, 46, 1);
  --errorWrapper-bg-color: rgba(199, 17, 17, 1);
  --progressBar-color: rgba(0, 96, 223, 1);
  --scrollbar-color: rgba(121, 121, 123, 1);
  --scrollbar-bg-color: rgba(35, 35, 39, 1);
  --sidebar-bg-color: rgba(50, 50, 52, 1);
  --toolbar-bg-color: rgba(56, 56, 61, 1);
  --toolbar-border-color: rgba(12, 12, 13, 1);
  --button-hover-color: rgba(102, 102, 103, 1);
  --toggled-btn-bg-color: rgba(0, 0, 0, 0.3);
  --dropdown-btn-bg-color: rgba(74, 74, 79, 1);
  --separator-color: rgba(0, 0, 0, 0.3);
  --field-color: rgba(250, 250, 250, 1);
  --field-bg-color: rgba(64, 64, 68, 1);
  --field-border-color: rgba(115, 115, 115, 1);
  --findbar-nextprevious-btn-bg-color: rgba(89, 89, 89, 1);
  --outline-color: rgba(255, 255, 255, 0.8);
  --outline-hover-color: rgba(255, 255, 255, 0.9);
  --outline-active-color: rgba(255, 255, 255, 0.08);
  --outline-active-bg-color: rgba(255, 255, 255, 1);
  --sidebaritem-bg-color: rgba(255, 255, 255, 0.15);
  --doorhanger-bg-color: rgba(74, 74, 79, 1);
  --doorhanger-border-color: rgba(39, 39, 43, 1);
  --doorhanger-hover-color: rgba(93, 94, 98, 1);
  --doorhanger-separator-color: rgba(92, 92, 97, 1);
  --overlay-button-bg-color: rgba(92, 92, 97, 1);
  --overlay-button-hover-color: rgba(115, 115, 115, 1);
}
</style>
